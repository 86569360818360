import apiClient from "../lib/apiClient";

export const fetchArchivedAssistants = async (): Promise<any[]> => {
  try {
    const response = await apiClient.get<{
      status: string;
      message: string;
      data: any[];
    }>("/assistant/retrieve/archive");
    return response.data.data;
  } catch (error: any) {
    throw new Error(
      error.response?.data ||
        "Failed to fetch archived assistants. Please try again."
    );
  }
};

export const removeArchivedAssistant = async (ref: string): Promise<void> => {
  try {
    await apiClient.delete(`/assistant/remove/archive/${ref}`);
  } catch (error: any) {
    throw new Error(
      error.response?.data ||
        "Failed to remove archived assistant. Please try again."
    );
  }
};

export const uploadArchivedAssistant = async (
  formData: FormData
): Promise<any> => {
  try {
    const response = await apiClient.post<{
      status: string;
      message: string;
      data: any;
    }>("/assistant/upload/archive", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data.data;
  } catch (error: any) {
    throw new Error(
      error.response?.data ||
        "Failed to upload archived assistant. Please try again."
    );
  }
};
