import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  FiSend,
  FiCopy,
  FiMic,
  FiVolume2,
  FiPause,
  FiX,
  FiPaperclip,
} from "react-icons/fi";
import { Button } from "../../../components/ui/Button";
import { TextArea } from "../../../components/ui/TextArea";
import { Undo2 } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import { fetchClassroomByIdThunk } from "../../../store/slices/classroomSlice";
import { RootState, AppDispatch } from "../../../store";
import { motion } from "framer-motion";
import {
  sendClassroomMessage,
  sendClassroomToolMessage,
  sendClassroomOutlineMessage,
} from "../../../api/studentclassroom";
import { sendClassroomOutlineAssessment } from "../../../api/studentassignment";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import greyImg from "../../../assets/img/greyimg.avif";
import {
  SpeechRecognition,
  SpeechRecognitionEvent,
  SpeechRecognitionResultList,
} from "../../../interfaces";
import MarkdownRenderer from "../_components/MarkdownRenderer";
import { useMaterialTailwindController } from "../../../context";
import { Sidenav } from "../classrooms/components/Sidenav";
import { markOutlineAsRead } from "../../../api/studentassignment";
import { Routes, Route } from "react-router-dom";
import { Cog6ToothIcon } from "@heroicons/react/24/solid";
import {
  DashboardNavbar,
  Configurator,
  Footer,
} from "../../../components/layout";
const Classroom = () => {
  const [inputText, setInputText] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const recognitionRef = useRef<SpeechRecognition | null>(null);
  const speechRef = useRef<SpeechSynthesisUtterance | null>(null);
  const [selectedTool, setSelectedTool] = useState<string | null>(null);
  const [selectedOutline, setSelectedOutline] = useState<any>(null);
  const [messages, setMessages] = useState<{
    [key: string]: { text: string; fromUser: boolean; isLoading?: boolean }[];
  }>({ main: [] });
  const [userDetails, setUserDetails] = useState<any>(null);
  const [welcomeMessage, setWelcomeMessage] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(false);

  const classroom = useSelector(
    (state: RootState) => state.classrooms.selectedClassroom
  );
  const tools = classroom?.tools || [];
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const { controller } = useMaterialTailwindController();
  const { sidenavType } = controller;
  const [isCollapsed, setIsCollapsed] = useState(false);

  const [isEmailVerified, setIsEmailVerified] = useState<number>(0);

  useEffect(() => {
    if (id) {
      dispatch(fetchClassroomByIdThunk(Number(id)));
    }
  }, [dispatch, id]);

  useEffect(() => {
    let newMessage: string = classroom?.content || "";

    if (newMessage && selectedTool) {
      newMessage = `Hi👋 ${
        userDetails?.firstname || "there"
      }, welcome to <strong>${selectedTool}</strong>. Start your conversation here!`;
    } else if (newMessage && selectedOutline) {
      newMessage = selectedOutline.path || "Welcome to the selected outline.";
    } else if (!newMessage && selectedOutline) {
      newMessage = selectedOutline.path || "Welcome to the selected outline.";
    } else if (!newMessage) {
      newMessage = `Hi👋 ${
        userDetails?.firstname || "there"
      }, welcome to class "<strong>${
        classroom?.classroom_name || "the classroom"
      }</strong>." Start your conversation here!`;
    }

    setWelcomeMessage(newMessage);
  }, [selectedOutline, selectedTool, classroom, userDetails]);

  const sendAnswer = (outlineAssessmentId: number, question: string) => {
    const selectedAnswer = document.querySelector(
      `input[name="${outlineAssessmentId}"]:checked`
    ) as HTMLInputElement;

    if (selectedAnswer) {
      setLoading(true);

      const answerData = {
        classroom_id: classroom?.classroom_id,
        outline_id: selectedOutline?.classroomoutline_id,
        answers: [
          // Change 'answers' to an array
          {
            outline_assessment_id: outlineAssessmentId,
            question: question,
            student_answer: selectedAnswer.value,
          },
        ],
      };

      sendClassroomOutlineAssessment(answerData)
        .then((response) => {
          console.log("Answer sent:", response);
          setLoading(false);
          dispatch(fetchClassroomByIdThunk(Number(id)));
          window.location.reload();
        })
        .catch((error) => {
          console.error("Error sending answer:", error);
          setLoading(false);
        });
    } else {
      alert("Please select an answer.");
    }
  };

  const sendAllAnswers = (assessments: any[]) => {
    const answerData = {
      classroom_id: classroom?.classroom_id,
      outline_id: selectedOutline?.classroomoutline_id,
      answers: assessments
        .map((assessment) => {
          const selectedAnswer = document.querySelector(
            `input[name="${assessment.outlineassessment_id}"]:checked`
          ) as HTMLInputElement;

          return {
            outline_assessment_id: assessment.outlineassessment_id,
            question: assessment.outlineassessment_question,
            student_answer: selectedAnswer?.value || null,
          };
        })
        .filter((answer) => answer.student_answer !== null),
    };

    if (answerData.answers.length === 0) {
      alert("Please select answers for all questions.");
      return;
    }

    setLoading(true);

    sendClassroomOutlineAssessment(answerData)
      .then((response) => {
        console.log("Answers sent:", response);
        setLoading(false);
        dispatch(fetchClassroomByIdThunk(Number(id)));
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error sending answers:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages, selectedTool]);

  useEffect(() => {
    const userDetailsFromStorage = localStorage.getItem("ai-teacha-user");
    if (userDetailsFromStorage) {
      const parsedDetails = JSON.parse(userDetailsFromStorage);
      setUserDetails(parsedDetails);
    }
  }, []);

  interface MessageData {
    classroom_id: number;
    classname: string;
    description: string;
    scope_restriction: boolean;
    grade: string;
    student_message: string;
    content_from: string;
    file_content: any[];
    tool_name?: string;
    tool_id?: number;
    tool_description?: string;
    outline_content?: any;
    outline_title?: any;
  }

  const getMessageKey = () => {
    if (selectedOutline) {
      return "outline";
    }
    return selectedTool || "main";
  };

  const currentMessages =
    selectedOutline &&
    (!messages["outline"] || messages["outline"].length === 0)
      ? []
      : messages[getMessageKey()] || [];

  const handleMarkAsRead = async (outlineId: number) => {
    if (!classroom) return;

    setLoading(true);
    try {
      await markOutlineAsRead(outlineId, classroom.classroom_id);
      setSelectedOutline({ ...selectedOutline, mark_as_read: 1 });
      window.location.reload();
    } catch (error) {
      console.error("Error marking outline as read:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleSend = async () => {
    if (!inputText.trim()) return;
    setSelectedOutline(null);
    const currentKey = "main";
    setMessages((prev) => ({
      ...prev,
      [currentKey]: [
        ...(prev[currentKey] || []),
        { text: inputText, fromUser: true, isLoading: false },
      ],
    }));

    setInputText("");

    setMessages((prev) => ({
      ...prev,
      [currentKey]: [
        ...(prev[currentKey] || []),
        {
          text: `${classroom?.author} AI Assistant typing...`,
          fromUser: false,
          isLoading: true,
        },
      ],
    }));

    let messageData: MessageData = selectedTool
      ? {
          classroom_id: classroom?.classroom_id || 0,
          classname: classroom?.classroom_name || "",
          description: classroom?.classroom_description || "",
          scope_restriction: classroom?.scope_restriction || true,
          grade: classroom?.grade || "",
          student_message: inputText,
          content_from: "classroom_tools",
          file_content:
            classroom?.classroomresources?.map(
              (resource) => resource.file_content
            ) || [],
          tool_name: selectedTool || "",
          tool_id:
            tools.find((tool) => tool.tool_name === selectedTool)?.tool_id || 0,
          tool_description:
            tools.find((tool) => tool.tool_name === selectedTool)
              ?.tool_description || "",
        }
      : {
          classroom_id: classroom?.classroom_id || 0,
          classname: classroom?.classroom_name || "",
          scope_restriction: classroom?.scope_restriction || true,
          description: classroom?.classroom_description || "",
          grade: classroom?.grade || "",
          file_content: classroom?.classroomresources
            ? classroom.classroomresources.map(
                (resource) => resource.file_content
              ) || []
            : [],
          student_message: inputText,
          content_from: "classroom",
        };

    if (selectedOutline) {
      messageData = {
        ...messageData,
        outline_content: selectedOutline?.path || "",
        outline_title: selectedOutline?.name || "",
      };
    }

    let response: any;

    try {
      if (selectedOutline) {
        response = await sendClassroomOutlineMessage(messageData);
      } else {
        response = selectedTool
          ? await sendClassroomToolMessage(messageData)
          : await sendClassroomMessage(messageData);
      }

      setMessages((prev) => ({
        ...prev,
        [currentKey]: [
          ...(prev[currentKey] || []).filter((msg) => !msg.isLoading),
          { text: response.data, fromUser: false },
        ],
      }));
    } catch (error) {
      console.error("Error sending message:", error);
      setMessages((prev) => ({
        ...prev,
        [currentKey]: [
          ...(prev[currentKey] || []).filter((msg) => !msg.isLoading),
          {
            text: "An error occurred. Please try again later.",
            fromUser: false,
          },
        ],
      }));
    }
  };

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
    alert("Copied to clipboard!");
  };
  const preprocessText = (text: string) => {
    return text.replace(/\*\*/g, "").replace(/###/g, "").replace(/\n/g, " ");
  };

  const toggleRecording = () => {
    if (!("webkitSpeechRecognition" in window)) {
      alert("Your browser does not support speech recognition.");
      return;
    }

    if (isRecording) {
      recognitionRef.current?.stop();
      setIsRecording(false);
      return;
    }

    const SpeechRecognition =
      (window as any).webkitSpeechRecognition ||
      (window as any).SpeechRecognition;
    const recognition = new SpeechRecognition();
    recognition.lang = "en-US";
    recognition.interimResults = true;

    recognition.onstart = () => setIsRecording(true);
    recognition.onend = () => setIsRecording(false);

    recognition.onresult = (
      event: SpeechRecognitionEvent & {
        resultIndex: number;
        results: SpeechRecognitionResultList;
      }
    ) => {
      let finalTranscript = "";
      for (let i = event.resultIndex; i < event.results.length; i++) {
        const transcript = event.results[i][0].transcript;
        if (event.results[i].isFinal) {
          finalTranscript += transcript + " ";
        }
      }
      console.log(finalTranscript);
      setInputText((prev) => prev + finalTranscript);
    };

    recognitionRef.current = recognition;
    recognition.start();
  };

  const handleTextToSpeech = (text: string) => {
    const cleanText = preprocessText(text);
    if (isSpeaking) {
      window.speechSynthesis.cancel();
      setIsSpeaking(false);
      return;
    }

    const speech = new SpeechSynthesisUtterance(cleanText);
    speech.lang = "en-US";

    speech.onend = () => setIsSpeaking(false);
    speechRef.current = speech;

    window.speechSynthesis.speak(speech);
    setIsSpeaking(true);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  return (
    <div className="min-h-screen bg-[#F1F1F1]">
      <Sidenav
        brandName="AI Teacha"
        outlines={(classroom?.classroomoutlines || []).map((outline) => ({
          name: outline.classroomoutline_title,
          path: outline.classroomoutline_content || "#",
          classroomoutline_id: outline.classroomoutline_id,
          assessmentStatus: outline.assessment_status,
          assessments: outline.assessments,
          mark_as_read: outline.mark_as_read,
        }))}
        tools={tools}
        selectedTool={selectedTool}
        onSelectTool={setSelectedTool}
        onToggle={(collapsed) => setIsCollapsed(collapsed)}
        selectedOutline={selectedOutline}
        onSelectOutline={setSelectedOutline}
      />
      <div
        className={`flex-1 transition-all duration-300 px-2  ${
          isCollapsed ? "xl:ml-28" : "xl:ml-72"
        }`}
      >
        <DashboardNavbar />
        <div className="mt-4 lg:mt-12">
          <div className="flex items-center  justify-between flex-col sm:flex-row">
            <div className="mx-auto text-center mt-4 sm:mt-0">
              <h2 className="text-xl sm:text-2xl font-semibold text-gray-900">
                {classroom?.classroom_name}
              </h2>
              <p className="text-sm sm:text-md font-medium  max-w-xl text-gray-900">
                {classroom?.classroom_description
                  ? classroom.classroom_description.length > 170
                    ? `${classroom.classroom_description.slice(0, 170)}...`
                    : classroom.classroom_description
                  : ""}
              </p>
              {selectedTool && (
                <p className="text-primary text-sm font-bold">
                  In use: {selectedTool}
                </p>
              )}
            </div>
          </div>
          <div className="relative flex flex-col lg:flex-row max-h-[550px]  overflow-y-auto pb-[100px] lg:pb-[70px]">
            <div className="flex-grow overflow-y-auto bg-gray-50 border border-gray-300 rounded-lg shadow-inner space-y-2 m-4 p-4 max-h-[450px]">
              {currentMessages.length === 0 ? (
                <>
                  <MarkdownRenderer
                    content={welcomeMessage}
                    className="text-sm lg:text-md text-gray-800"
                  />

                  {selectedOutline && selectedOutline.mark_as_read === 0 && (
                    <Button
                      onClick={() =>
                        handleMarkAsRead(selectedOutline.classroomoutline_id)
                      }
                      variant={"gradient"}
                      className="rounded-md"
                      disabled={loading}
                    >
                      {loading ? "Marking..." : "Mark as Read"}
                    </Button>
                  )}
                  {selectedOutline &&
                    selectedOutline.assessments &&
                    selectedOutline.assessments.length > 0 &&
                    selectedOutline.assessmentStatus === "pending" && (
                      <>
                        <hr />
                        <br />
                        <h2>Take Assessments:</h2>
                        <br />
                        {selectedOutline.assessments.map((assessment: any) => (
                          <div key={assessment.outlineassessment_id}>
                            Question: {assessment.outlineassessment_question}
                            <br />
                            {(() => {
                              try {
                                const options = JSON.parse(
                                  assessment.outlineassessment_options
                                );
                                if (Array.isArray(options)) {
                                  return (
                                    <>
                                      {options.map((option, index) => (
                                        <div
                                          key={`${assessment.outlineassessment_id}-${index}`}
                                        >
                                          <input
                                            type="radio"
                                            id={`${assessment.outlineassessment_id}-${index}`}
                                            name={
                                              assessment.outlineassessment_id
                                            }
                                            value={option}
                                          />
                                          &ensp;
                                          <label
                                            htmlFor={`${assessment.outlineassessment_id}-${index}`}
                                          >
                                            {option}
                                          </label>
                                          <br />
                                        </div>
                                      ))}

                                      <br />
                                      <br />
                                    </>
                                  );
                                } else {
                                  return `Options: ${assessment.outlineassessment_options}<br><br>`;
                                }
                              } catch (e) {
                                return `Options: ${assessment.outlineassessment_options}<br><br>`;
                              }
                            })()}
                          </div>
                        ))}

                        <Button
                          onClick={() =>
                            sendAllAnswers(selectedOutline.assessments)
                          }
                          variant={"gradient"}
                          className="rounded-md mt-4"
                          disabled={loading}
                        >
                          {loading ? "Submitting..." : "Submit All Answers"}
                        </Button>
                      </>
                    )}
                  {selectedOutline &&
                    selectedOutline.assessments &&
                    selectedOutline.assessments.length > 0 &&
                    selectedOutline.assessmentStatus !== "pending" && (
                      <>
                        <hr />
                        <h2>Assessment Taken</h2>
                      </>
                    )}
                </>
              ) : (
                currentMessages.map((message, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, scale: 0.9 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.3, delay: index * 0.1 }}
                    className={`flex flex-col mb-2 ${
                      message.fromUser ? "items-end" : "items-start"
                    }`}
                  >
                    {!message.fromUser && (
                      <div className="flex justify-end space-x-4 mb-1">
                        <button
                          onClick={() => handleCopy(message.text)}
                          className="text-gray-600 hover:text-gray-800 flex gap-1"
                        >
                          <FiCopy /> <span className="text-sm">Copy</span>
                        </button>
                        <button
                          onClick={() => handleTextToSpeech(message.text)}
                          className="text-gray-600 hover:text-gray-800 flex gap-1"
                        >
                          {isSpeaking ? <FiPause /> : <FiVolume2 />}
                          <span className="text-sm">
                            {isSpeaking ? "Pause" : "Voice"}
                          </span>
                        </button>
                      </div>
                    )}
                    <MarkdownRenderer
                      content={message.text}
                      className={`p-3 text-sm ${
                        message.fromUser
                          ? "bg-primary max-w-xs text-white rounded-tl-lg"
                          : "bg-gray-200 max-w-xl text-black rounded-tr-lg"
                      }`}
                      style={{ wordWrap: "break-word", whiteSpace: "pre-wrap" }}
                    />
                  </motion.div>
                ))
              )}
              <div ref={messagesEndRef} />
            </div>
          </div>

          <div className="fixed bottom-0 left-0 w-full bg-white  border-t lg:flex lg:w-[calc(100%-5rem)] lg:ml-[5rem] flex-col lg:flex-row">
            <div className="flex justify-between items-center gap-24 w-full">
              <div
                className="w-64 h-20 bg-cover bg-center relative hidden lg:block"
                style={{ backgroundImage: `url(${greyImg})` }}
              >
                <span className="absolute inset-0 flex items-center text-sm italic justify-center text-white text-lg font-bold bg-black bg-opacity-20">
                  Powered By <span className="text-lg ml-1"> Zyra</span>
                </span>
              </div>

              <div className="relative p-2 lg:-ml-24 flex items-center w-full">
                <TextArea
                  value={inputText}
                  onChange={(e) => setInputText(e.target.value)}
                  onKeyDown={handleKeyDown}
                  placeholder="Type your message..."
                  className="flex-grow pr-16 px-3 py-2 border text-md rounded-lg"
                />
                <button
                  onClick={toggleRecording}
                  className={`absolute right-16 top-1/2 transform -translate-y-1/2 p-3 rounded-full ${
                    isRecording
                      ? "bg-red-500 text-white"
                      : "bg-gray-200 text-black"
                  }`}
                >
                  <FiMic />
                </button>
                <button
                  onClick={handleSend}
                  aria-label="Send Message"
                  disabled={inputText.trim().length === 0}
                  className="absolute right-2 top-1/2 transform -translate-y-1/2 p-2 bg-primary text-white rounded-full mr-4"
                >
                  <FiSend className="w-5 h-5" />
                </button>
              </div>
            </div>

            <Button
              onClick={() => setIsDrawerOpen(true)}
              variant={"gradient"}
              className="flex items-center bg-white w-full rounded-md text-black lg:hidden mt-2 mx-auto"
            >
              View Tools
            </Button>
          </div>

          <Drawer
            open={isDrawerOpen}
            onClose={() => setIsDrawerOpen(false)}
            direction="bottom"
            className="lg:hidden"
          >
            <div className="p-4">
              <h3 className="text-xl font-semibold">Class Tools</h3>
              <ul className="space-y-4 mt-4">
                <li
                  className={`cursor-pointer px-4 py-2 rounded-lg ${
                    selectedTool === null ? "bg-primary text-white" : ""
                  }`}
                  onClick={() => setSelectedTool(null)}
                >
                  Main Classroom
                </li>
                {tools.map((tool) => (
                  <li
                    key={tool.tool_id}
                    className={`capitalize cursor-pointer px-4 py-2 rounded-lg ${
                      selectedTool === tool.tool_name
                        ? "bg-primary text-white"
                        : ""
                    }`}
                    onClick={() => setSelectedTool(tool.tool_name)}
                  >
                    {tool.tool_name}
                  </li>
                ))}
              </ul>
            </div>
          </Drawer>
        </div>
      </div>
    </div>
  );
};

export default Classroom;
