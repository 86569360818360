import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchFreeUsers } from "../../../store/slices/userSlice";
import { RootState, AppDispatch } from "../../../store";
import { formatDateWithSuffix } from "./Date";
const FreeUsers = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { freeUsers, loading, error } = useSelector(
    (state: RootState) => state.users
  );

  const [currentPage, setCurrentPage] = useState(1);
  const usersPerPage = 12;

  useEffect(() => {
    dispatch(fetchFreeUsers());
  }, [dispatch]);

  // Pagination logic
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = freeUsers.slice(indexOfFirstUser, indexOfLastUser);

  return (
    <div className="p-4">
      <h2 className="text-2xl font-semibold mb-4">Free Users</h2>
      {loading && <p>Loading...</p>}
      {error && <p className="text-red-500">{error}</p>}

      <div className="overflow-x-auto">
        <table className="w-full border-collapse border border-gray-300 shadow-md">
          <thead>
            <tr className="bg-gray-100 text-gray-700">
              <th className="border p-3">First Name</th>
              <th className="border p-3">Last Name</th>
              <th className="border p-3">Email</th>
              <th className="border p-3">Phone</th>
              <th className="border p-3">Joined Date</th>
            </tr>
          </thead>
          <tbody>
            {currentUsers.map((user, index) => (
              <tr
                key={user.email}
                className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}
              >
                <td className="border p-3">{user.firstname}</td>
                <td className="border p-3">{user.lastname}</td>
                <td className="border p-3">{user.email}</td>
                <td className="border p-3">{user.phone}</td>
                <td className="border p-3">
                  {formatDateWithSuffix(user.created_at)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination Controls */}
      <div className="flex justify-between items-center mt-4">
        <button
          className="px-4 py-2 bg-gray-300 rounded disabled:opacity-50"
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
        >
          Previous
        </button>

        <span className="text-gray-700">
          Page {currentPage} of {Math.ceil(freeUsers.length / usersPerPage)}
        </span>

        <button
          className="px-4 py-2 bg-gray-300 rounded disabled:opacity-50"
          onClick={() =>
            setCurrentPage((prev) =>
              Math.min(prev + 1, Math.ceil(freeUsers.length / usersPerPage))
            )
          }
          disabled={indexOfLastUser >= freeUsers.length}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default FreeUsers;
