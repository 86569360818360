import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
const AIPolicy = () => {
  return (
    <div>
      <Navbar />
      <section className="bg-gray-100 text-gray-800 py-24 ">
        <section className="relative bg-blight w-full h-[60vh] pt-[5rem] flex justify-center bg-gradient-to-r from-[#07052D] to-[#171093] items-center overflow-hidden overlow-hidden">
          <span className="absolute inse=t-0 z-0 p-5  justif-center top-[rem]"></span>
          <section>
            <figcaption className="desc z-10 relative px-2">
              <h1 className="text-3xl md:text-4xl lg:text-6xl font-bold text-center my-2 text-header text-white">
                {" "}
                AI Policy
              </h1>
              <h2 className="text-xl  text-gray-200 text-center">
                Effective Date: 01/07/2023 <br /> Last Updated: 11/12/2024
              </h2>
            </figcaption>
          </section>
        </section>

        <div className="max-w-5xl mx-auto px-6">
          <h2 className="text-3xl md:text-4xl lg:text-4xl font-bold text-primary mt-6 text-center mb-6"></h2>
          <p className="text-lg leading-relaxed mb-8">
            <span className="text-lg font-bold">AI Teacha &nbsp;</span>
            is committed to utilizing artificial intelligence (AI) responsibly
            and ethically to revolutionize education, empower educators, and
            enhance student learning outcomes. This AI Policy outlines our
            principles, guidelines, and compliance measures to ensure the
            responsible use of AI within the AI Teacha Learning Management
            System (LMS). It adheres to global standards and is adapted to the
            context of Nigeria, Africa, and other regions worldwide.
          </p>

          <div className="p-6 md:p-10 space-y-6">
            <section>
              <h2 className="text-xl font-semibold border-b pb-2">
                1. Purpose of the Policy
              </h2>
              <p>
                This policy governs the use of artificial intelligence (AI)
                technologies within the AI Teacha LMS. It ensures ethical,
                transparent, and lawful application of AI tools while
                prioritizing the rights, privacy, and welfare of all users,
                including educators, students, parents, and administrators.
              </p>
              <ul className="list-disc list-inside mt-2">
                <li>
                  Comply with data protection laws, including NDPR, GDPR, and
                  COPPA.
                </li>
                <li>Ensure inclusivity and accessibility in education.</li>
                <li>
                  Protect user privacy and foster a safe learning environment.
                </li>
              </ul>
            </section>

            <section>
              <h2 className="text-xl font-semibold border-b pb-2">
                2. Scope of the Policy
              </h2>
              <p>
                This policy applies to all AI functionalities integrated into AI
                Teacha LMS, including but not limited to:
              </p>
              <ul className="list-disc list-inside mt-2">
                <li>Personalized learning pathways and recommendations.</li>
                <li>AI-powered tools for classroom engagement.</li>
                <li>Automated grading and feedback systems.</li>
                <li>Student performance analytics.</li>
                <li>AI-assisted content creation and planning.</li>
                <li>Virtual assistants and chatbots for user support.</li>
              </ul>
            </section>

            <section>
              <h2 className="text-xl font-semibold border-b pb-2">
                3. Ethical Principles
              </h2>
              <ul className="list-disc list-inside mt-2">
                <li>
                  <strong>Transparency:</strong> Users are informed when and how
                  AI technologies are applied.
                </li>
                <li>
                  <strong>Fairness:</strong> AI systems are rigorously tested to
                  minimize biases.
                </li>
                <li>
                  <strong>Accountability:</strong> Mechanisms for user feedback
                  and issue resolution are in place.
                </li>
                <li>
                  <strong>Privacy:</strong> Adherence to NDPR, GDPR, and similar
                  regulations.
                </li>
                <li>
                  <strong>Inclusivity:</strong> Tools are designed for diverse
                  cultural and linguistic contexts.
                </li>
                <li>
                  <strong>Safety:</strong> Applications are assessed for
                  potential risks, especially for vulnerable groups.
                </li>
              </ul>
            </section>

            <section>
              <h2 className="text-xl font-semibold border-b pb-2">
                4. Data Collection and Privacy
              </h2>
              <p>
                AI Teacha is committed to protecting user privacy and adhering
                to global data protection standards:
              </p>
              <ul className="list-disc list-inside mt-2">
                <li>
                  <strong>Data Minimization:</strong> Only essential data is
                  collected.
                </li>
                <li>
                  <strong>User Consent:</strong> Data is collected with explicit
                  user consent.
                </li>
                <li>
                  <strong>Compliance:</strong> Alignment with NDPR, GDPR, and
                  Malabo Convention.
                </li>
                <li>
                  <strong>Data Retention:</strong> User data is retained only as
                  necessary.
                </li>
                <li>
                  <strong>Data Security:</strong> Encryption and regular audits
                  safeguard user data.
                </li>
              </ul>
            </section>

            <section>
              <h2 className="text-xl font-semibold border-b pb-2">
                5. AI Functionality Guidelines
              </h2>
              <p>
                AI Teacha integrates AI functionalities to enhance learning
                experiences:
              </p>
              <ul className="list-disc list-inside mt-2">
                <li>
                  Personalized learning pathways tailored to student
                  preferences.
                </li>
                <li>Automated grading tools requiring educator oversight.</li>
                <li>Analytics to provide actionable insights for educators.</li>
                <li>
                  AI-assisted content creation to support lesson planning.
                </li>
                <li>
                  AI chatbots and virtual assistants for real-time support.
                </li>
              </ul>
            </section>

            <section>
              <h2 className="text-xl font-semibold border-b pb-2">
                6. Child Protection Compliance
              </h2>
              <p>
                AI Teacha adheres to Nigeria’s Child Rights Act, COPPA, and
                other child protection laws. Enhanced safeguards ensure ethical
                AI use for students, with parental or guardian consent required
                for processing minors' data.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold border-b pb-2">
                7. Continuous Monitoring and Improvement
              </h2>
              <p>
                AI systems are regularly audited, and user feedback is valued to
                address biases, inaccuracies, or risks while maintaining
                compliance with ethical standards.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold border-b pb-2">
                8. Contact Information
              </h2>
              <p>For inquiries or concerns, contact:</p>
              <ul className="list-disc list-inside mt-2">
                <li>Email: support@aiteacha.com</li>
                <li>Phone: 070 89115000</li>
                <li>
                  Address: 65, Gbasemo Street, Aga Ikorodu, Lagos, Nigeria
                </li>
              </ul>
            </section>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default AIPolicy;
