import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AppDispatch, RootState } from "../../../store";
import {
  fetchArchivedAssistantsThunk,
  removeArchivedAssistantThunk,
  uploadArchivedAssistantThunk,
} from "../../../store/slices/archivedAssistantsSlice";
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogTitle,
  DialogDescription,
  DialogFooter,
  DialogClose,
} from "../../../components/ui/Dialogue";
import { Input } from "../../../components/ui/Input";
import { Button } from "../../../components/ui/Button";
import { Skeleton } from "../../../components/ui/Skeleton";

const ArchivedAssistants = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { archivedAssistants, loading, error } = useSelector(
    (state: RootState) => state.archivedAssistants
  );

  const [deleteRef, setDeleteRef] = useState<string | null>(null);
  const [uploadTitle, setUploadTitle] = useState("");
  const [uploadFile, setUploadFile] = useState<File[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    dispatch(fetchArchivedAssistantsThunk());
  }, [dispatch]);

  const handleDelete = () => {
    if (deleteRef) {
      setIsDeleting(true);
      dispatch(removeArchivedAssistantThunk(deleteRef))
        .then(() => dispatch(fetchArchivedAssistantsThunk()))
        .finally(() => {
          setDeleteRef(null);
          setIsDeleting(false);
        });
    }
  };

  const handleUpload = () => {
    if (uploadTitle && uploadFile.length > 0) {
      setIsUploading(true);

      const formData = new FormData();
      formData.append("title", uploadTitle);
      uploadFile.forEach((file) => formData.append("documents", file));

      dispatch(uploadArchivedAssistantThunk(formData))
        .then(() => dispatch(fetchArchivedAssistantsThunk()))
        .finally(() => {
          setUploadTitle("");
          setUploadFile([]);
          setIsUploading(false);
        });
    }
  };

  if (loading) {
    return (
      <div className="grid grid-cols-1 mt-12 sm:grid-cols-2 md:grid-cols-3 gap-4">
        {[...Array(6)].map((_, index) => (
          <Skeleton key={index} className="h-24 w-full rounded-lg" />
        ))}
      </div>
    );
  }

  return (
    <div className="mt-6">
      <h2>Archived Assistants</h2>
      {error && <p style={{ color: "red" }}>{error}</p>}
      <div className="my-4">
        <Dialog>
          <DialogTrigger asChild>
            <Button variant={"gradient"} className="rounded-md">
              Upload Archive
            </Button>
          </DialogTrigger>
          <DialogContent>
            <DialogTitle>Upload Doc</DialogTitle>
            <Input
              type="text"
              placeholder="Title"
              value={uploadTitle}
              onChange={(e) => setUploadTitle(e.target.value)}
            />

            <input
              type="file"
              multiple
              accept=".pdf, .png, .jpeg, .jpg, .doc, .docx"
              onChange={(e) => {
                const files = Array.from(e.target.files || []).filter((file) =>
                  [
                    "application/pdf",
                    "image/png",
                    "image/jpeg",
                    "image/jpg",
                    "application/msword",
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                  ].includes(file.type)
                );

                if (files.length === 0) {
                  alert(
                    "Only PDF, PNG, JPEG, DOC, and DOCX files are allowed."
                  );
                  return;
                }

                setUploadFile(files);
              }}
            />

            <DialogFooter>
              <DialogClose asChild>
                <Button
                  variant={"destructive"}
                  className="rounded-md"
                  disabled={isUploading}
                >
                  Cancel
                </Button>
              </DialogClose>
              <Button
                onClick={handleUpload}
                className="rounded-md"
                disabled={!uploadTitle || !uploadFile || isUploading}
              >
                {isUploading ? "Uploading..." : "Upload"}
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        {archivedAssistants.map((assistant: any) => (
          <div
            key={assistant.ref}
            className="border border-gray-300 p-4 rounded-lg shadow-sm bg-white"
          >
            <h3 className="text-lg font-semibold">{assistant.title}</h3>
            <Dialog>
              <DialogTrigger asChild>
                <Button
                  onClick={() => setDeleteRef(assistant.ref)}
                  variant="destructive"
                  className="mt-4 rounded-md"
                >
                  Delete
                </Button>
              </DialogTrigger>
              <DialogContent>
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogDescription>
                  Are you sure you want to delete this archived assistant?
                </DialogDescription>
                <DialogFooter>
                  <DialogClose asChild>
                    <Button
                      variant="outline"
                      className="rounded-md"
                      disabled={isDeleting}
                    >
                      Cancel
                    </Button>
                  </DialogClose>
                  <Button
                    variant="destructive"
                    className="rounded-md"
                    onClick={handleDelete}
                    disabled={isDeleting}
                  >
                    {isDeleting ? "Deleting..." : "Confirm"}
                  </Button>
                </DialogFooter>
              </DialogContent>
            </Dialog>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ArchivedAssistants;
