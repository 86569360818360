import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";

import {
  useMaterialTailwindController,
  setOpenSidenav,
} from "../../../../context/index";
import Text from "../../../../components/ui/Text";
import { Button } from "../../../../components/ui/Button";
import brandImg from "../../../../logo.png";
import { Undo2, List } from "lucide-react";

interface SidenavProps {
  brandName?: string;
  outlines: any[];
  tools: any[];
  selectedTool?: string | null;
  onSelectTool?: (tool: string | null) => void;
  onToggle?: (collapsed: boolean) => void;
  selectedOutline?: any;
  onSelectOutline?: (outline: any) => void;
}

type SidenavType = "dark" | "white" | "transparent";

export function Sidenav({
  brandName = "AI Teacha",
  outlines,
  tools,
  selectedTool,
  onSelectTool,
  onToggle,
  selectedOutline,
  onSelectOutline,
}: SidenavProps) {
  const { controller, dispatch } = useMaterialTailwindController();
  const { sidenavColor, sidenavType, openSidenav } = controller as {
    sidenavColor: string;
    sidenavType: SidenavType;
    openSidenav: boolean;
  };

  const sidenavRef = React.useRef<HTMLDivElement>(null);

  const sidenavTypes: Record<SidenavType, string> = {
    dark: "bg-gradient-to-br from-gray-800 to-gray-900",
    white: "bg-white shadow-sm",
    transparent: "bg-transparent",
  };
  const [isCollapsed, setIsCollapsed] = React.useState(false);
  const navigate = useNavigate();

  const handleToggle = () => {
    const newCollapsedState = !isCollapsed;
    setIsCollapsed(newCollapsedState);
    if (onToggle) {
      onToggle(newCollapsedState);
    }
  };

  const handleSelectOutlineInternal = (outline: any) => {
    if (selectedOutline && selectedOutline.mark_as_read === 0) {
      const selectedIndex = outlines.findIndex((o) => o === outline);

      if (
        selectedIndex > 0 &&
        outlines[selectedIndex - 1] &&
        outlines[selectedIndex - 1].mark_as_read === 0
      ) {
        alert("Please read the previous outline first.");
      } else {
        onSelectOutline?.(outline);
        onSelectTool?.(null);
      }
    } else {
      onSelectOutline?.(outline);
      onSelectTool?.(null);
    }
  };

  const handleSelectToolInternal = (tool: any) => {
    if (onSelectTool) {
      onSelectTool(tool);
    }
    if (onSelectOutline) {
      onSelectOutline(null);
    }
  };

  return (
    <aside
      ref={sidenavRef}
      className={`${sidenavTypes[sidenavType]} ${
        openSidenav ? "translate-x-0" : "-translate-x-80"
      } fixed inset-0 z-50 h-[calc(100vh)] ${
        isCollapsed ? "w-28 " : "w-72"
      } transition-transform duration-300 xl:translate-x-0`}
    >
      <div className="relative flex items-center justify-between p-4">
        <Link to={"/"}>
          <div className="flex items-center">
            {brandImg && !isCollapsed && (
              <img src={brandImg} alt="Brand Logo" className="h-8 w-8 mr-2" />
            )}
            {!isCollapsed && (
              <Text variant="large" className="text-center text-black">
                {brandName}
              </Text>
            )}
          </div>
        </Link>
        <Button
          variant={"default"}
          className="p-2 rounded-full xl:inline-block hidden"
          onClick={handleToggle}
        >
          {isCollapsed ? (
            <ChevronRightIcon className="h-5 w-5 text-gray-700" />
          ) : (
            <ChevronLeftIcon className="h-5 w-5 text-gray-700" />
          )}
        </Button>

        <Button
          variant={"default"}
          className="absolute right-0 top-0 p-2 rounded-br-none rounded-tl-none xl:hidden"
          onClick={() => setOpenSidenav(dispatch, false)}
        >
          <XMarkIcon strokeWidth={2.5} className="h-5 w-5 text-gray-700" />
        </Button>
      </div>

      <nav className="flex-1 p-4">
        <Button
          className="flex items-center rounded-md w-full  gap-3 py-2"
          onClick={() => navigate(-1)}
          variant={"gray"}
        >
          <Undo2 size={"1.1rem"} color="black" />
          Back
        </Button>

        {!isCollapsed && (
          <>
            <div className="flex items-center gap-2 mt-4">
              <List size={20} color="gray" />
              <Text className="text-lg text-gray-800 font-semibold uppercase tracking-wider">
                Classroom Outlines
              </Text>
            </div>

            <div className="max-h-60 overflow-y-auto">
              {outlines.length > 0 ? (
                outlines.map((outline, index) => (
                  <div
                    key={index}
                    className={`flex flex-col gap-1 p-3 rounded-lg cursor-pointer ${
                      selectedOutline?.name === outline.name
                        ? "bg-gray-400 text-white"
                        : "bg-gray-100 hover:bg-gray-500 hover:text-black"
                    } transition-all duration-300 mb-2`}
                    onClick={() => handleSelectOutlineInternal(outline)}
                  >
                    <span className="text-sm font-medium ">
                      {`Course Outline ${index + 1}: ${outline.name
                        .replace(/#/g, "")
                        .replace(/[*`~_>!-]/g, "")}`}
                    </span>
                  </div>
                ))
              ) : (
                <div className="p-3 text-center">No Outlines Available</div>
              )}
            </div>
          </>
        )}
      </nav>
      {!isCollapsed && (
        <div className="mt-auto p-4">
          <h3 className="text-lg font-semibold mb-2">Class Tools</h3>

          <div
            key="main"
            className={`border cursor-pointer px-4 py-3 rounded-lg ${
              selectedTool === null ? "bg-primary text-white" : ""
            }`}
            onClick={() => handleSelectToolInternal(null)}
          >
            Main Classroom
          </div>

          <ul className="max-h-40 overflow-y-auto">
            {tools.map((tool, index) => (
              <li
                key={index}
                onClick={() => handleSelectToolInternal(tool.tool_name)}
                className={`flex items-center gap-2 p-2 rounded-lg cursor-pointer ${
                  selectedTool === tool.tool_name
                    ? "bg-primary text-white"
                    : "hover:bg-gray-700"
                }`}
              >
                {!isCollapsed && <span>{tool.tool_name}</span>}
              </li>
            ))}
          </ul>
        </div>
      )}
    </aside>
  );
}
